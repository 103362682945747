<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="8">
        <v-toolbar>
          <v-toolbar-title v-if="selected_plan" class="primary--text mx-4">{{ selected_plan.name }}</v-toolbar-title>
          <v-chip v-if="selected_plan" color="blue" class="white--text darken-3">{{
              selected_plan.category.name
            }}
          </v-chip>
          <v-chip v-if="selected_plan" color="primary" class="mx-4 white--text">{{ selected_plan.site_name }}</v-chip>
          <v-spacer></v-spacer>
          <v-progress-circular indeterminate color="primary" v-if="printing"></v-progress-circular>
          <v-btn color="primary" class="mx-2" :disabled="printing" @click="printPlan">Imprimer</v-btn>
          <v-btn class="primary--text" to="/plans" text>
            <v-icon>mdi-chevron-left</v-icon>
            Retour à la liste
          </v-btn>
        </v-toolbar>
        <v-row justify="center" align="center" v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
        <canvas id="plan" @click.exact="addPoint" @click.c.ctrl="storeLineStart" :width="width"
                :height="height"></canvas>
      </v-col>
      <v-col cols="4">
        <v-card max-height="100%" class="overflow-y-auto">
          <v-toolbar color="primary">
            <v-toolbar-title class="white--text">Édition du plan</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab">
            <v-tab>Liste</v-tab>
            <v-tab>Édition</v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-simple-table dense v-for="(points_group, key_icon) in grouped_points" :key="key_icon">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-center" colspan="3">
                        <v-img max-width="32" max-height="32" class="d-inline-block"
                               :src="'/img/' + key_icon + '.png'"></v-img>
                      </th>
                    </tr>
                    <tr>
                      <th>Nom de l'équipement</th>
                      <th>Balise</th>
                      <th>Note</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(point, index) in points_group" @mouseover="pointToHover = point"
                        @mouseleave="pointToHover = null" :key="index">
                      <td>
                        <router-link :to="'/assets/' + point.asset.id">{{ point.asset.name }}</router-link>
                      </td>
                      <td>{{ point.asset.balise }}</td>
                      <th>{{ point.note }}</th>
                      <th>
                        <v-icon @click="deletePoint(point.id)" color="red">mdi-trash-can</v-icon>
                        <EditEmplacementDialog :point="point"/>
                      </th>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item>
                <h4>Symbole de l'emplacement</h4>
                <v-row class="my-2" no-gutters>
                  <v-col v-for="icon in icons" :key="icon">
                    <v-img contain @click="selected_icon = icon" max-width="32" max-height="32"
                           :src="'/img/' + icon + '.png'"></v-img>
                  </v-col>
                </v-row>
                <div class="my-4">
                  <span class="my-3 font-italic grey--text darken-2">Actuellement sélectionné : </span>
                  <v-img contain max-width="32" max-height="32" :src="'/img/' + selected_icon + '.png'"></v-img>
                </div>
                <h4>Orientation du texte</h4>
                <v-radio-group row v-model="orientation">
                  <v-radio label="Haut" value="haut"></v-radio>
                  <v-radio label="Bas" value="bas"></v-radio>
                  <v-radio label="Gauche" value="gauche"></v-radio>
                  <v-radio label="Droite" value="droite"></v-radio>
                </v-radio-group>
                <v-text-field v-model="note" label="Note"></v-text-field>
                <h4>Équipements du site</h4>
                <v-row no-gutters justify="space-between">
                  <v-checkbox label="Montrer les équipements déjà placés" v-model="showPlaced"></v-checkbox>
                  <v-icon :disabled="refreshing" color="primary" @click="refreshAssets">mdi-refresh</v-icon>
                </v-row>
                <v-select label="Domaine" v-model="assetCategory" :items="domaines" item-text="name"
                          item-value="id"></v-select>
                <v-radio-group @change="onAssetChoice" v-model="placed_asset">
                  <v-data-iterator :items="filtered_assets_to_place" item-key="id">
                    <template v-slot:item="{ item }">
                      <v-row no-gutters>
                        <v-col cols="1">
                          <v-radio :value="item.id"></v-radio>
                        </v-col>
                        <v-col>
                          <v-card>
                            <v-card-title class="primary--text">{{ item.name }}</v-card-title>
                            <v-card-subtitle class="grey--text">{{ item.balise }} -
                              {{ item.category !== null ? item.category.name : "Non défini" }}
                            </v-card-subtitle>
                            <v-card-text>
                              {{ item.site_name }}
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-radio-group>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {groupBy} from "@/util/core";
import {SiteMixin} from "@/mixins/util/SiteMixin";
import EditEmplacementDialog from "@/views/EditEmplacementDialog";

export default {
  name: "Plan",
  components: {EditEmplacementDialog},
  mixins: [SiteMixin],
  async mounted() {
    let planId = this.$route.params?.id;
    let result = await this.$store.dispatch("plans/fetchPlanById", planId);
    if (result === "failure") {
      this.$store.dispatch("alerts/announceWarning", "Page introuvable");
      this.$router.go(-1);
      return;
    }
    if (this.$store.getters["categories/allCategories"].length === 0) {
      this.$store.dispatch("categories/fetchCategories");
    }
    await this.$store.dispatch("emplacements/fetchEmplacementsByPlanId", planId);
    this.assets_to_place = await this.$store.dispatch("assets/fetchAssetsBySite", {
      sites: [this.selected_plan.site],
      parameters: {mode: "plan"}
    })
    let element = document.getElementById("plan");
    this.ctx = element.getContext("2d");
    await this.drawPlan();
  },
  data() {
    return {
      ctx: null,
      icons: ["star", "clim", "acces", "site", "bati", "incendie", "danger", "evacuation"],
      selected_icon: "star",
      count: 0,
      orientation: "bas",
      tab: 0,
      pointToHover: null,
      loading: true,
      width: 800,
      height: 600,
      assets_to_place: [],
      note: "",
      placed_asset: null,
      showPlaced: false,
      printing: false,
      animation_frame: null,
      drawing: false,
      imageLoading: [],
      assetCategory: null,
      refreshing: false,
      line_x: null,
      line_y: null
    }
  },
  methods: {
    async drawPlan(animate = true, ctx = null) {
      let context = ctx !== null ? ctx : this.ctx;
      this.drawing = true;
      let image = new Image();
      image.src = this.selected_plan.base_image || "/img/default_plan.png";
      image.crossOrigin = "anonymous";
      let that = this;
      image.addEventListener('load', function () {
        context.drawImage(image, 0, 0);
        if (that.loading) {
          that.width = image.naturalWidth;
          that.height = image.naturalHeight;
          that.loading = false;
        }
      }, false);
      for (let point of this.points) {
        let pointImage = new Image();
        pointImage.src = point.image;
        pointImage.addEventListener('load', function () {
          if (point.line_x !== null && point.line_y !== null) {
            context.strokeStyle = "black";
            context.lineWidth = 2;
            context.beginPath();
            let center_x = point.x + (pointImage.width / 2);
            let center_y = point.y + (pointImage.height / 2);
            console.log(`point(line_x=${point.line_x}, line_y=${point.line_y}, x=${point.x}, y=${point.y}, `
                + `image_w=${pointImage.width}, image_h=${pointImage.height}, cx=${center_x}, cy=${center_y})`);
            context.moveTo(point.line_x, point.line_y);
            context.lineTo(center_x, center_y);

            context.stroke();
            context.beginPath();
            context.arc(point.line_x, point.line_y, 3, 0, 2 * Math.PI, false);
            context.fill();
          }
          context.drawImage(pointImage, point.x, point.y);
          context.fillStyle = "black";
          context.font = "18px serif";
          context.textAlign = "center";

          let orientationPoint = {
            "bas": [point.x + 16, point.y + pointImage.height + 18],
            "haut": [point.x + 16, point.y - 8],
            "droite": [point.x + 48, point.y + 20],
            "gauche": [point.x - 8, point.y + 20],
          }
          context.fillText(point.note, ...orientationPoint[point.orientation]);
        }, false);

      }
      if (this.pointToHover !== null) {
        this.hoverPoint(this.pointToHover)
      }
      if (animate) {
        this.animation_frame = requestAnimationFrame(this.drawPlan);
      }
    },
    async addPoint(event) {
      if (this.mode === "list" || this.placed_asset === null) {
        return;
      }
      let asset = this.assets_to_place.find((a) => a.id === this.placed_asset);
      if (asset === undefined) {
        return;
      }
      this.count++;
      let note = this.note === "" || this.note === null ? (this.currentMaxCount < 10 ? `0${this.currentMaxCount + 1}` : this.currentMaxCount + 1) : this.note;
      let planId = parseInt(this.$route.params?.id);
      if (isNaN(planId)) {
        return;
      }
      let result = await this.$store.dispatch("emplacements/postEmplacement", {
        "x": event.offsetX,
        "y": event.offsetY,
        "line_x": this.line_x,
        "line_y": this.line_y,
        "asset": asset.id,
        "note": note,
        "plan": planId,
        "icon": this.selected_icon,
        "orientation": this.orientation,
      });
      this.line_x = null;
      this.line_y = null;
      if (result !== null) {
        this.$store.dispatch("messages/announceSuccess", "Point placé");
        this.assets_to_place = this.assets_to_place.filter((a) => a.id !== asset.id);
        this.placed_asset = null;
      }

    },
    storeLineStart(event) {
      this.line_x = event.offsetX;
      this.line_y = event.offsetY;
    },
    async deletePoint(id) {
      let result = await this.$store.dispatch("emplacements/deleteEmplacement", {id: id});
      if (result === "success") {
        await this.$store.dispatch("messages/announceSuccess", "Point supprimé.");
      }
    },
    curlinevalue() {
      console.log(`line_x=${this.line_x},line_y=${this.line_y}`)
    },
    hoverPoint(point) {
      this.ctx.strokeStyle = "red";
      this.ctx.lineWidth = 1;
      this.ctx.strokeRect(point.x - 32, point.y - 32, 96, 96);
      this.ctx.strokeStyle = "black";
    },
    onAssetChoice(value) {
      this.resetLinePoint();
      let asset = this.assets_to_place.find((asset) => asset.id === value);
      const regex = /\d{1,2}/gm;
      let match = asset.name.match(regex);
      if (match !== null && match.length > 0) {
        this.note = match[0];
      }
    },
    async printPlan() {
      this.printing = true;
      let printCanvas = document.createElement("canvas");
      printCanvas.width = this.width;
      printCanvas.height = this.height;
      let printContext = printCanvas.getContext("2d");
      await this.drawPlan(false, printContext);
      await new Promise(r => setTimeout(r, 2000));
      let data = printCanvas.toDataURL();
      await this.$store.dispatch("plans/printPlan", {
        image: data,
        planId: this.selected_plan.id,
        plan: this.selected_plan
      });
      this.printing = false;
      await this.drawPlan();
    },
    async refreshAssets() {
      this.refreshing = true;
      this.assets_to_place = await this.$store.dispatch("assets/fetchAssetsBySite", {
        sites: [this.selected_plan.site],
        parameters: {mode: "plan"}
      })
      this.refreshing = false;
    },
    resetLinePoint() {
      this.line_x = null;
      this.line_y = null;
    }
  },
  computed: {
    grouped_points() {
      return groupBy(this.points, "icon");
    },
    selected_plan() {
      return this.$store.getters["plans/selected_plan"];
    },
    mode() {
      return this.tab === 0 ? "list" : "edit";
    },
    emplacements() {
      return this.$store.getters["emplacements/emplacements"];
    },
    filtered_assets_to_place() {
      return this.assets_to_place.filter((asset) => this.showPlaced ? true : asset.plans.length === 0)
          .filter((asset) => this.assetCategory !== null ? asset.category?.id === this.assetCategory : true);
    },
    points() {
      return this.emplacements.map((emplacement) => ({
        id: emplacement.id,
        image: (emplacement.icon === null) ? "/img/star.png" : `/img/${emplacement.icon}.png`,
        icon: emplacement.icon,
        asset: emplacement.asset,
        x: emplacement.x,
        y: emplacement.y,
        line_x: emplacement.line_x,
        line_y: emplacement.line_y,
        note: emplacement.note,
        orientation: emplacement.orientation,
      }))
    },
    currentMaxCount() {
      if (this.grouped_points[this.selected_icon] === undefined) {
        return 0;
      }
      let currentIconPointList = this.grouped_points[this.selected_icon];
      let currentlyUsedNumbersList = currentIconPointList.map((point) => parseInt(point.note)).filter((note) => !isNaN(note))
      return Math.max(...currentlyUsedNumbersList);
    },
    domaines() {
      return [{id: null, name: "Tout les domaines"}, ...this.$store.getters["categories/domaines"]];
    }
  }
}
</script>

