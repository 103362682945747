<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Modifier l'emplacement</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-4">
        <h4>Symbole de l'emplacement</h4>
        <v-row class="my-2" no-gutters>
          <v-col v-for="icon in icons" :key="icon">
            <v-img contain @click="pointModel.icon = icon" max-width="32" max-height="32"
                   :src="'/img/' + icon + '.png'"></v-img>
          </v-col>
        </v-row>
        <div class="my-4">
          <span class="my-3 font-italic grey--text darken-2">Actuellement sélectionné : </span>
          <v-img contain max-width="32" max-height="32" :src="'/img/' + pointModel.icon + '.png'"></v-img>
        </div>
        <h4>Orientation du texte</h4>
        <v-radio-group row v-model="pointModel.orientation">
          <v-radio label="Haut" value="haut"></v-radio>
          <v-radio label="Bas" value="bas"></v-radio>
          <v-radio label="Gauche" value="gauche"></v-radio>
          <v-radio label="Droite" value="droite"></v-radio>
        </v-radio-group>
        <v-text-field v-model="pointModel.note" label="Note"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="editPoint" :disabled="editing" class="white--text">Modifier</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="editing"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'EditEmplacementDialog',
  props: {
    point: {
      type: Object,
    }
  },
  data() {
    return {
      icons: ["star", "clim", "acces", "site", "bati", "incendie", "danger", "evacuation"],
      selected_icon: null,
      pointModel: JSON.parse(JSON.stringify(this.point)),
      dialog: false,
      editing: false,
    }
  },
  methods: {
    async editPoint() {
      this.editing = true;
      console.log(this.pointModel.asset instanceof Object);
      this.pointModel.asset = this.pointModel.asset instanceof Object ? this.pointModel.asset.id : this.pointModel.asset;
      console.log(this.pointModel);
      let result = await this.$store.dispatch("emplacements/patchEmplacement", this.pointModel);
      if(result !== null) {
        this.$store.dispatch("messages/announceSuccess", "Point modifié.");
        this.dialog = false;
      }
      this.editing = false;
    }
  }
}
</script>
